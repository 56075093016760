/* eslint-disable import/no-cycle */
import * as React from "react";

import { datadogLogs, HandlerType } from "@datadog/browser-logs";

import config from "./config";

datadogLogs.init(config.datadogLogging);

const handler: HandlerType = config.env === "local" ? "console" : "http";

const logger = datadogLogs.createLogger("webby-app", {
    level: "info",
    handler,
    context: { env: config.env },
});

export default logger;

export function useLogMountTime(componentName: string) {
    React.useEffect(() => {
        const mountTime = Date.now();

        return () => {
            const unmountTime = Date.now();
            const timeElapsed = unmountTime - mountTime;
            logger.info(`${componentName} was up for ${timeElapsed}ms`, {
                mountDuration: timeElapsed,
                componentName,
            });
        };
    }, [componentName]);
}
