import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function VolleyV(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon viewBox="0 0 211 188" {...props}>
            <path d="m 196.78,15.66378 h -20.46 l -46.91,81.17 a 22.822665,22.822665 0 1 1 -39.52,-22.84 l 33.71,-58.33 H 22.78 a 18.83,18.83 0 0 0 -16.2999989,28.24 L 93.48,194.49378 a 18.83,18.83 0 0 0 32.6,0 l 87,-150.59 a 18.83,18.83 0 0 0 -16.3,-28.24 z" />
        </SvgIcon>
    );
}
