import getEnv from "../../util/getEnv";

const bucket = () => {
    const env = getEnv();
    if (env === "production") return "volley-production-media";
    if (env === "rc") return "volley-rc-media";
    return "volley-staging-media";
};

export default (fileName: string) => `/cdn/gcs/${bucket()}/${fileName}`;
